// import { TopCapsule } from "@/components/capsule/top/TopCapsule";
import { LandingLayout } from "@/components/landing/layout/LandingLayout";
// import { LandingFooter } from "@/components/landing/layout/footer/LandingFooter";
// import { LandingHeader } from "@/components/landing/layout/header/LandingHeader";
// import HENGE from "@henge-inc/global-types";
// import HUI from "@henge-inc/ui/v1_2";
// import landingBeltImageFile from "@public/assets/images/landing/Belt.webp";
// import landingGaiaImageFile from "@public/assets/images/landing/Gaia.webp";
// import landingHengeImageFile from "@public/assets/images/landing/Henge.webp";
// import Image from "next/image";
// import { useOverlayScrollbars } from "overlayscrollbars-react";
import { useEffect } from "react";
import { useRouter } from "next/router";

const LandingPage = () => {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    router.replace("/vault/Wonders");
  }, [router]);

  return <></>;

  // const pageRef = useRef<HTMLDivElement>(null!);
  //
  // const [initialize] = useOverlayScrollbars({
  //   options: {
  //     overflow: {
  //       x: "hidden",
  //       y: "scroll",
  //     },
  //     scrollbars: {
  //       theme: "os-theme-dark",
  //     },
  //   },
  //   defer: {},
  // });
  //
  // useEffect(() => {
  //   initialize(pageRef.current);
  // }, [initialize]);
  //
  // return (
  //   <>
  //     <div
  //       ref={pageRef}
  //       className="w-full h-full overflow-x-hidden overflow-y-scroll no-scrollbar bg-white"
  //     >
  //       <TopCapsule setShareOpen={() => {}} />
  //       {/* <LandingHeader /> */}
  //       <div className="overflow-hidden">
  //         <div className="relative w-full h-[100vh] flex flex-row items-center justify-center gap-4">
  //           <div className="max-[640px]:flex-col px-4 flex w-full max-w-[1200px] gap-8">
  //             <div className="flex justify-center items-start flex-col gap-4">
  //               <p className="size-sm text-6xl font-light whitespace-nowrap">
  //                 For your 3D
  //               </p>
  //               <HUI.Text
  //                 kind={HENGE.TEXT_KIND.heading3}
  //                 weight={HENGE.TEXT_WEIGHT.light}
  //                 align={HENGE.TEXT_ALIGN.left}
  //                 whiteSpace={HENGE.TEXT_WHITE_SPACE.nowrap}
  //               >
  //                 Make a showcase with your works
  //               </HUI.Text>
  //             </div>
  //             <div className="max-w-[900px]">
  //               <Image
  //                 src={landingHengeImageFile}
  //                 alt={"Henge image"}
  //                 quality={80}
  //                 priority={true}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="bg-[#FDFDFD] relative w-full min-h-screen flex flex-col items-center justify-center gap-4 pt-64">
  //           <div className="flex justify-center items-center flex-col gap-4">
  //             <p className="size-sm text-6xl font-light">All at once</p>
  //             <HUI.Text
  //               kind={HENGE.TEXT_KIND.heading3}
  //               weight={HENGE.TEXT_WEIGHT.light}
  //               align={HENGE.TEXT_ALIGN.left}
  //             >
  //               Build your project collection
  //             </HUI.Text>
  //           </div>
  //           <Image
  //             className="h-[100%] max-w-[1200px] w-full"
  //             src={landingGaiaImageFile}
  //             alt={"Gaia image"}
  //             quality={80}
  //             priority={true}
  //           />
  //         </div>
  //         <div className="bg-[#FDFDFD] pl-4 pb-16 relative w-full min-h-screen flex flex-col items-end justify-center gap-4 overflow-visible">
  //           <div className="flex absolute top-[5%] justify-center items-center flex-col gap-4 w-full z-20">
  //             <p className="px-4 size-sm text-6xl font-light max-w-[1200px] w-full">
  //               3D Archive for ...
  //             </p>
  //             <HUI.Text
  //               className="max-w-[1200px] w-full px-4"
  //               kind={HENGE.TEXT_KIND.heading3}
  //               weight={HENGE.TEXT_WEIGHT.light}
  //               align={HENGE.TEXT_ALIGN.left}
  //             >
  //               Archive everything, Boldly
  //             </HUI.Text>
  //           </div>
  //
  //           <Image
  //             className="h-full w-full max-w-[1600px] right-0 brightness-[1.025]"
  //             src={landingBeltImageFile}
  //             alt={"Belt image"}
  //             quality={80}
  //             priority={true}
  //           />
  //         </div>
  //       </div>
  //
  //       <LandingFooter />
  //     </div>
  //   </>
  // );
};

LandingPage.getLayout = LandingLayout;

export default LandingPage;
